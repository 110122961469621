import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from '../../_services/api.service';

@Component({
  selector: 'app-monument-delete',
  templateUrl: './monument-delete.component.html',
  styleUrls: ['./monument-delete.component.css']
})
export class MonumentDeleteComponent implements OnInit {

  _id: any;

  constructor(public activeModal: NgbActiveModal, private apiService: ApiService) { }

  ngOnInit() {

  }

  onDelete() {
    this.apiService.deleteMonument(this._id).subscribe((res) => {
      if (res['status'] == 'success') this.activeModal.close(res['data']['deletedMonument']);
    });
  }
}
