import { Component, Input, Output, OnInit, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import { MarkerManager, GoogleMapsAPIWrapper, MapTypeStyle, LatLngBounds, LatLngLiteral, LatLng } from "@agm/core";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

type EnrichedLatLngBounds = Omit<LatLngBounds, "contains"> & {
  contains(latLng: LatLngLiteral): boolean;
};

@Component({
  selector: "app-google-map",
  providers: [MarkerManager, GoogleMapsAPIWrapper],
  templateUrl: "./google-map.component.html",
  styleUrls: ["./google-map.component.scss"],
})
export class GooglemapComponent implements OnInit {
  @Input() height = 400;
  @Input() styles: MapTypeStyle[] = [];
  @Input() latitude: Number;
  @Input() longitude: Number;
  @Input() zoom: Number;
  @Input() mapTypeControl: Boolean;
  @Input() fullscreenControl: Boolean;
  @Input() markers: any;
  @Input() displayedMarkers: any;
  @Output() mapReady = new EventEmitter();
  @Output() boundsChange = new EventEmitter();
  @Output() idle = new EventEmitter();
  @Output() markerClick = new EventEmitter();

  position: LatLngLiteral = { lat: 0, lng: 0};

  clusterMode = true;

  constructor() {
  }

  ngOnInit() {
  }

  onMapReady(event) {
    this.mapReady.emit(event);
  }

  onBoundsChange(newBounds: EnrichedLatLngBounds) {
    /* this.displayedMarkers = this.markers.filter(marker => {
      this.position.lat = marker.position.lat
      this.position.lng = marker.position.lng;
      return newBounds.contains(this.position)
    })*/
    this.boundsChange.emit();
  }

  onIdle() {
    this.idle.emit();
    // this.boundsChange.emit();
  }

  onMarkerClick(id) {
    this.markerClick.emit(id);
  }
}
