import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  NgxGalleryOptions,
  NgxGalleryImage,
  NgxGalleryAnimation,
  NgxGalleryImageSize,
} from "ngx-gallery";
import "hammerjs";

import { ApiService } from "../../_services/api.service";
import { StorageService } from "../../_services/storage.service";

declare var $: any;

@Component({
  selector: "app-monument-view",
  templateUrl: "./monument-view.component.html",
  styleUrls: ["./monument-view.component.scss"],
})
export class MonumentViewComponent implements OnInit {
  _id: any;
  ref: any;
  isAdmin: boolean;
  isLimited: boolean; // TODO
  isSelection: boolean = false;
  showTraffic: boolean = false;
  monument: Object;
  faces: Array<any>;
  category: any;
  traffic: any;
  brand: any;
  note: any;
  medias: Array<any>;
  galleryImages: NgxGalleryImage[];
  galleryOptions: NgxGalleryOptions[];
  map: any;
  trafficLayer: any;
  trafficNum;

  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private storageService: StorageService,
  ) {}

  randomInt(max, min) {
    return Math.round(Math.random() * (max - min)) + min;
  }

  ngOnInit() {
    var user = this.storageService.get("user");
    this.isAdmin =
      user.auth.role == "super" || user.auth.role == "admin" ? true : false;
    this.isLimited = user.info.grant && user.info.grant.hierarchy.limited; // TODO

    // this.monument = null;
    this.faces = [];
    this.category = null;
    this.traffic = "";
    this.brand = null;
    this.note = "";
    this.medias = [];
    this.galleryImages = [];
    this.galleryOptions = [
      {
        width: "100%",
        height: "540px",
        arrowPrevIcon: "mdi mdi-chevron-left",
        arrowNextIcon: "mdi mdi-chevron-right",
        closeIcon: "mdi mdi-close",
        fullscreenIcon: "mdi mdi-fullscreen",
        thumbnailsColumns: 4,
        imagePercent: 85,
        thumbnailsPercent: 15,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        imageAnimation: NgxGalleryAnimation.Slide,
        preview: true,
        previewFullscreen: true,
        previewKeyboardNavigation: true,
        lazyLoading: true
      },
    ];

    this.trafficLayer = new google.maps.TrafficLayer();

    const monument_type_coef = this.randomInt(2, 7);
    const color_coef = this.randomInt(500, 2000);
    const random_num = this.randomInt(1, 300);
    this.trafficNum = monument_type_coef*100 + color_coef + random_num;
    this.loadApi();
  }

  loadApi() {
    if (this.isSelection) {
      this.apiService.getProjectById(this._id, this.ref).subscribe((res) => {
        if (res["status"] == "success") {
          this.monument = res["data"];

          for (var i = 0; i < this.monument["face"]; i++) this.faces.push(i);
          this.loadFaceData(0);
        }
      });
    } else {
      this.apiService.getMonumentById(this._id).subscribe((res) => {
        if (res["status"] == "success") {
          this.monument = res["data"]["monument"];

          for (var i = 0; i < this.monument["face"]; i++) this.faces.push(i);
          this.loadFaceData(0);
        }
      });
    }
  }

  monumentUpdate() {
    this.activeModal.close("update");
  }

  monumentDel() {
    this.activeModal.close("delete");
  }

  faceChange(event, index) {
    $(".matteus-face-buttons .btn").removeClass("active");
    $(event.target).addClass("active");

    setTimeout(() => {
      $(".ngx-gallery-image-wrapper audio").remove();
      $(".ngx-gallery-image-wrapper video").remove();

      this.loadFaceData(index);
    }, 10);
  }

  loadFaceData(index) {
    if (this.monument["faceInfos"][index]) {
      this.category = this.monument["faceInfos"][index].category;
      this.traffic = this.monument["faceInfos"][index].traffic;
      this.brand = this.monument["faceInfos"][index].brand;
      this.note = this.monument["faceInfos"][index].note;

      this.medias = this.monument["faceInfos"][index].medias;
      this.galleryImages = [];
      this.medias.forEach((ele) => {
        var galleryImage;

        if (ele.type == "image")
          galleryImage = {
            small: ele.url,
            medium: ele.url,
            big: ele.url,
          };
        else if (ele.type == "audio")
          galleryImage = {
            small: "assets/images/custom/bg-audio.png",
            medium: "assets/images/custom/bg-audio.png",
            big: "assets/images/custom/bg-audio.png",
          };
        else if (ele.type == "video")
          galleryImage = {
            small: "assets/images/custom/bg-video.png",
            medium: "assets/images/custom/bg-video.png",
            big: "assets/images/custom/bg-video.png",
          };

        this.galleryImages.push(galleryImage);
      });
    }
  }

  mediaChange(event) {
    var index, width, height, style, audio, video;

    index = event.index;
    width = $(".ngx-gallery-image.ngx-gallery-active").css("width");
    height = $(".ngx-gallery-image.ngx-gallery-active").css("height");

    setTimeout(() => {
      $(".ngx-gallery-image-wrapper audio").remove();
      $(".ngx-gallery-image-wrapper video").remove();

      if (this.medias[index].type == "audio") {
        style =
          'style="width:' +
          width +
          "; height:" +
          height +
          '; background:url(assets/images/custom/bg-audio.png); background-size:100% 100%"';
        audio =
          "<audio controls " +
          style +
          '><source src="' +
          this.medias[index].url +
          '" type="audio/mp3"></audio>';
        $(".ngx-gallery-image.ngx-gallery-inactive-left").after(audio);
        $(".ngx-gallery-image.ngx-gallery-active").css("display", "none");
      } else if (this.medias[index].type == "video") {
        style = 'style="width:' + width + "; height:" + height + '"';
        video =
          "<video controls " +
          style +
          '><source src="' +
          this.medias[index].url +
          '" type="video/mp4"></video>';
        $(".ngx-gallery-image.ngx-gallery-inactive-left").after(video);
        $(".ngx-gallery-image.ngx-gallery-active").css("display", "none");
      }
    }, 10);
  }

  mapReady(event) {
    this.map = event;
  }

  onShowTraffic() {
    if (!this.showTraffic) {
      this.trafficLayer.setMap(this.map);
    } else {
      this.trafficLayer.setMap(null);
    }
  }
}
