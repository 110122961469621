import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GooglemapComponent } from "./google-map/google-map.component";
import { AgmCoreModule } from "@agm/core";
import { AgmJsMarkerClustererModule } from "@agm/js-marker-clusterer";
import { UiSwitchModule } from "ngx-ui-switch";

// Import FusionCharts library and chart modules
import { FusionChartsModule, FusionChartsComponent } from "angular-fusioncharts";
import * as FusionCharts from "fusioncharts";
import * as charts from "fusioncharts/fusioncharts.charts";
import * as FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

FusionChartsModule.fcRoot(FusionCharts, charts, FusionTheme);

@NgModule({
  declarations: [GooglemapComponent],
  exports: [GooglemapComponent, FusionChartsComponent],
  imports: [
    CommonModule,
    AgmCoreModule,
    AgmJsMarkerClustererModule,
    UiSwitchModule,
    FusionChartsModule,
  ],
})
export class SharedModule {}
