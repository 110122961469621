import { Component, AfterViewInit, OnInit } from "@angular/core";
import {
  ROUTES_ADMIN,
  ROUTES_OPERATOR,
  ROUTES_VERIFIER,
  ROUTES_LIMITED, // TODO
  ROUTES_ANALYTICS,
} from "./menu-items";
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { StorageService } from "../../_services/storage.service";
declare var $: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  showMenu = "";
  showSubMenu = "";
  public sidebarnavItems: any[];

  sidebarnavTitle: string = "";
  sidebarnavDescription: string = "";

  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = "0";
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = "0";
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService
  ) {}

  // End open close
  ngOnInit() {
    var user = this.storageService.get("user"),
      role = user.auth.role;

    const type = this.storageService.get("type");
    if (type === "admin") {
      this.sidebarnavTitle = "Analytics";
      this.sidebarnavDescription =
        "Select the type of report to process the data and set the configuration display";
      this.sidebarnavItems = ROUTES_ANALYTICS.filter(
        (sidebarnavItem) => sidebarnavItem
      );
    } else {
      if (role == "super" || role == "admin") {
        // TODO
        if (user.info.grant && user.info.grant.hierarchy.limited) {
          this.sidebarnavItems = ROUTES_LIMITED.filter(
            (sidebarnavItem) => sidebarnavItem
          );
        } else {
          this.sidebarnavItems = ROUTES_ADMIN.filter(
            (sidebarnavItem) => sidebarnavItem
          );
        }
      } else if (role == "operator")
        this.sidebarnavItems = ROUTES_OPERATOR.filter(
          (sidebarnavItem) => sidebarnavItem
        );
      else if (role == "verifier")
        this.sidebarnavItems = ROUTES_VERIFIER.filter(
          (sidebarnavItem) => sidebarnavItem
        );
    }
  }

  tryLogout(title) {
    if (title == "Log out") {
      const type = this.storageService.get("type");
      this.storageService.clear();
      this.router.navigateByUrl(type === "admin" ? "/admin-login" : "/login");
    }
  }

  navItemClass(item) {
    return `${item.title == "Log out" ? "logout-item" : ""} ${
      item.path !== "##" ? "" : "sep"
    }`;
  }
}
