import { Injectable } from "@angular/core";
import { formatNumber } from "@angular/common";
import * as clone from "clone";
import * as moment from "moment";

const paletteColors = [
  "#F5C340",
  "#E8814C",
  "#409090",
  "#785E6B",
  "#2196F3",
  "#00C853",
  "#FF7F3B",
  "#9C27B0",
  "#E91E63",
  "#9013FE",
  "#4A90E2",
  "#B8E986",
  "#9B9B9B",
];

@Injectable({
  providedIn: "root",
})
export class UtilService {
  constructor() {}

  getSecret() {
    return "adsmart";
  }

  getNameOfCountry() {
    return "Guatemala";
  }

  getCenterOfCountry() {
    return {
      lat: 14.628434,
      lng: -90.522713,
    };
  }

  getCenter(polyData) {
    var latSum = 0,
      lngSum = 0;

    for (var i = 0; i < polyData.length; i++) {
      latSum += polyData[i]["lat"];
      lngSum += polyData[i]["lng"];
    }

    return {
      lat: latSum / polyData.length,
      lng: lngSum / polyData.length,
    };
  }

  dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  deepClone(obj) {
    return clone(obj);
  }

  getDropDownSettings(overwrite: any = {}) {
    return {
      singleSelection: false,
      idField: "id",
      textField: "text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
      ...overwrite,
    };
  }

  getGrants() {
    return [
      {
        mod: { val: "hierarchy", text: "Hierarchy" },
        features: [
          { val: "limited", text: "Limited access!", type: "input" }, // TODO
          { val: "dependency", text: "Manage dependencies" },
          { val: "poi", text: "Manage point of interests" },
        ],
      },
      {
        mod: { val: "user", text: "User type" },
        features: [
          { val: "operator", text: "Manage operators" },
          { val: "verifier", text: "Manage verifiers" },
        ],
      },
      {
        mod: { val: "ads", text: "Advertisement setup" },
        features: [
          { val: "category", text: "Manage categories" },
          { val: "type", text: "Manage types" },
          { val: "brand", text: "Manage brands" },
          { val: "provider", text: "Manage providers" },
          { val: "cycle", text: "Manage cycles" },
        ],
      },
      {
        mod: { val: "global", text: "Global heatmap" },
        features: [],
      },
      {
        mod: { val: "field", text: "Field operations" },
        features: [],
      },
      {
        mod: { val: "analytics", text: "Analytics" },
        features: [],
      },
    ];
  }

  local2utc(local) {
    return moment(local).utc();
  }

  utc2local(utc) {
    return moment.utc(utc).local();
  }

  dp2utc(dp) {
    var local, utc;

    local = new Date(dp.year, dp.month - 1, dp.day, 0, 0, 0, 0);
    utc = this.local2utc(local);
    return utc;
  }

  utc2dp(utc) {
    var local, dp;

    local = this.utc2local(utc);
    dp = {
      year: parseInt(local.format("YYYY")),
      month: parseInt(local.format("MM")),
      day: parseInt(local.format("DD")),
    };
    return dp;
  }

  getTodayUTC(offset = 0) {
    var today, local, utc;

    today = new Date();
    local = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + offset,
      0,
      0,
      0,
      0
    );
    utc = this.local2utc(local);
    return utc.format();
  }

  getTodayLocal() {
    return moment().format("YYYY-MM-DD");
  }

  utcFormat(utc) {
    var local, formatted;

    local = this.utc2local(utc);
    formatted = local.format("YYYY-MM-DD");
    return formatted;
  }

  getFusionChartConfig(type, info: any = {}) {
    const defaults = {
      paletteColors,
      theme: "fusion",
    };
    switch (type) {
      case "Column2d":
      case "Bar2d":
        return {
          ...defaults,
          plotSpacePercent: 50,
          ...info.config,
        };
      case "MsColumn2d":
        return {
          ...defaults,
          formatnumberscale: "1",
          theme: "fusion",
          drawcrossline: "1",
          showLegend: false,
          ...info.config,
        };
      case "MsBar2d":
        return {
          ...defaults,
          drawcrossline: "1",
          formatnumberscale: "1",
          legendAllowDrag: "1",
          legendPosition: "left-top",
          showLegend: "1",
          yAxisPosition: "top",
          ...info.config,
        };
      case "Doughnut2d":
        return {
          ...defaults,
          centerLabelBold: "1",
          decimals: "0",
          defaultCenterLabel: `Total: ${
            (info.config && info.config.numberPrefix) || ""
          }${formatNumber(info.total, "en-US")}`,
          doughnutRadius: 120,
          enableMultiSlicing: "0",
          enableSmartLabels: "0",
          labelDistance: "10",
          labelFontColor: "#ffffff",
          labelPosition: "inside",
          minAngleForLabel: "360",
          pieRadius: "150",
          showLegend: "0",
          showPercentValues: "1",
          startingAngle: "90",
          ...info.config,
        };
      case "Doughnut2d-small":
        return {
          ...defaults,
          centerLabelBold: "1",
          decimals: "0",
          defaultCenterLabel: `Total: ${
            (info.config && info.config.numberPrefix) || ""
          }${formatNumber(info.total, "en-US")}`,
          doughnutRadius: 90,
          enableMultiSlicing: "0",
          enableSmartLabels: "0",
          labelFontColor: "#ffffff",
          legendPosition: "right",
          minAngleForLabel: "360",
          pieRadius: "110",
          showLabels: "0",
          showPercentInTooltip: "1",
          showPercentValues: "1",
          startingAngle: "90",
          ...info.config,
        };
      default:
        return defaults;
    }
  }
}
