import { Routes, RouterModule } from "@angular/router";

import { FullComponent } from "./layouts/full/full.component";
import { BlankComponent } from "./layouts/blank/blank.component";
import { AuthGuard } from "./_guards/auth.guard";

export const routes: Routes = [
  {
    path: "",
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
      },
      {
        path: "",
        loadChildren: () =>
          import("./pages/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("./pages/hierarchy/hierarchy.module").then(
            (m) => m.HierarchyModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("./pages/user/user.module").then((m) => m.UserModule),
      },
      {
        path: "",
        loadChildren: () =>
          import("./pages/ads/ads.module").then((m) => m.AdsModule),
      },
      {
        path: "",
        loadChildren: () =>
          import("./pages/global/global.module").then((m) => m.GlobalModule),
      },
      {
        path: "",
        loadChildren: () =>
          import("./pages/field/field.module").then((m) => m.FieldModule),
      },
      {
        path: "",
        loadChildren: () =>
          import("./pages/analytics/analytics.module").then(
            (m) => m.AnalyticsModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("./pages/verification/verification.module").then(
            (m) => m.VerificationModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("./pages/admin/admin.module").then((m) => m.AdminModule),
      },
    ],
  },
  {
    path: "",
    component: BlankComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/auth/auth.module").then((m) => m.AuthModule),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "404",
  },
];

export class AppRoutingModule {}
