import { RouteInfo } from "./sidebar.metadata";

export const ROUTES_ADMIN: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: "mdi mdi-view-dashboard",
    class: "",
    extralink: false,
    submenu: [],
  },

  {
    path: "",
    title: "Global operations",
    icon: "",
    class: "nav-small-cap",
    extralink: true,
    submenu: [],
  },

  {
    path: "",
    title: "Hierarchy",
    icon: "mdi mdi-notification-clear-all",
    class: "has-arrow",
    extralink: false,
    submenu: [
      {
        path: "/hierarchy/dependency",
        title: "Dependencies",
        icon: "",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/hierarchy/point-of-interest",
        title: "Point of interests",
        icon: "",
        class: "",
        extralink: false,
        submenu: [],
      },
    ],
  },

  {
    path: "",
    title: "User type",
    icon: "mdi mdi-account",
    class: "has-arrow",
    extralink: false,
    submenu: [
      {
        path: "/user/admin",
        title: "Administrators",
        icon: "",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/operator",
        title: "Operators",
        icon: "",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/user/verifier",
        title: "Verifiers",
        icon: "",
        class: "",
        extralink: false,
        submenu: [],
      },
    ],
  },

  {
    path: "",
    title: "Advertisement setup",
    icon: "mdi mdi-checkbox-multiple-blank-outline",
    class: "has-arrow",
    extralink: false,
    submenu: [
      {
        path: "/ads/category",
        title: "Categories",
        icon: "",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/ads/type",
        title: "Types",
        icon: "",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/ads/brand",
        title: "Brands",
        icon: "",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/ads/provider",
        title: "Providers",
        icon: "",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/ads/cycle",
        title: "Cycles",
        icon: "",
        class: "",
        extralink: false,
        submenu: [],
      },
    ],
  },

  {
    path: "",
    title: "Display & Analysis",
    icon: "",
    class: "nav-small-cap",
    extralink: true,
    submenu: [],
  },

  {
    path: "/global",
    title: "Global heatmap",
    icon: "mdi mdi-earth",
    class: "",
    extralink: false,
    submenu: [],
  },

  {
    path: "/field",
    title: "Field operations",
    icon: "mdi mdi-human",
    class: "",
    extralink: false,
    submenu: [],
  },

  {
    path: "/analytics",
    title: "Analytics",
    icon: "mdi mdi-chart-pie",
    class: "",
    extralink: false,
    submenu: [],
  },

  {
    path: "",
    title: "Log out",
    icon: "mdi mdi-logout",
    class: "log-out",
    extralink: false,
    submenu: [],
  },
];

export const ROUTES_OPERATOR: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: "mdi mdi-view-dashboard",
    class: "",
    extralink: false,
    submenu: [],
  },

  {
    path: "",
    title: "Global operations",
    icon: "",
    class: "nav-small-cap",
    extralink: true,
    submenu: [],
  },

  {
    path: "",
    title: "Hierarchy",
    icon: "mdi mdi-notification-clear-all",
    class: "has-arrow",
    extralink: false,
    submenu: [
      {
        path: "/hierarchy/dependency",
        title: "Dependencies",
        icon: "",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/hierarchy/point-of-interest",
        title: "Point of interests",
        icon: "",
        class: "",
        extralink: false,
        submenu: [],
      },
    ],
  },

  {
    path: "",
    title: "Verifications",
    icon: "mdi mdi-account",
    class: "has-arrow",
    extralink: false,
    submenu: [
      {
        path: "/verification/assignment",
        title: "Assignments",
        icon: "",
        class: "",
        extralink: false,
        submenu: [],
      },
      {
        path: "/verification/control",
        title: "Control",
        icon: "",
        class: "",
        extralink: false,
        submenu: [],
      },
    ],
  },

  {
    path: "",
    title: "Log out",
    icon: "mdi mdi-logout",
    class: "log-out",
    extralink: false,
    submenu: [],
  },
];

export const ROUTES_VERIFIER: RouteInfo[] = [
  {
    path: "",
    title: "Log out",
    icon: "mdi mdi-logout",
    class: "log-out",
    extralink: false,
    submenu: [],
  },
];

export const ROUTES_LIMITED: RouteInfo[] = [
  // TODO
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: "mdi mdi-view-dashboard",
    class: "",
    extralink: false,
    submenu: [],
  },

  {
    path: "",
    title: "Global operations",
    icon: "",
    class: "nav-small-cap",
    extralink: true,
    submenu: [],
  },

  {
    path: "",
    title: "Hierarchy",
    icon: "mdi mdi-notification-clear-all",
    class: "has-arrow",
    extralink: false,
    submenu: [
      {
        path: "/hierarchy/dependency",
        title: "Dependencies",
        icon: "",
        class: "",
        extralink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Log out",
    icon: "mdi mdi-logout",
    class: "log-out",
    extralink: false,
    submenu: [],
  },
];

export const ROUTES_ANALYTICS: RouteInfo[] = [
  // TODO
  {
    path: "/admin/general",
    title: "General",
    icon: "",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/admin/brands",
    title: "Brand based",
    icon: "",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/admin/competition",
    title: "Competition based",
    icon: "",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "##",
    title: "",
    icon: "",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "/admin/cycles",
    title: "Admin per cycle analytics",
    icon: "",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: "#",
    title: "Log out",
    icon: "mdi mdi-logout",
    class: "log-out",
    extralink: false,
    submenu: [],
  },
];
