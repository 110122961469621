import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UploadFileService {

    private s3Bucket = environment.s3_bucket;
    private s3BucketForMonumentMedia = environment.s3_bucket_monument;

    constructor() {

    }

    async uploadFile(folder, file) {
        return new Promise((resolve, reject) => {
            const contentType = file.type;
            const ext = file.type.split('/')[1];
            const bucket = new S3(
                {
                    accessKeyId: 'AKIATJPJBEYFQJ67GWDV',
                    secretAccessKey: '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',
                    region: 'us-east-1'
                }
            );
            let Key;
            let Bucket;
            const commonParams = {
                Bucket: this.s3Bucket,
                Key: folder + '/' + Date.now() + '.' + ext,
                Body: file,
                ACL: 'public-read',
                ContentType: contentType
            }

            if (folder === 'monument' && ['jpeg', 'jpg', 'png'].includes(ext)) {
                Key = Date.now() + '.' + ext;
                Bucket = this.s3BucketForMonumentMedia;
            } else {
                Key = commonParams.Key;
                Bucket = commonParams.Bucket;
            }

            let params = {
                Bucket,
                Key,
                Body: file,
                ACL: 'public-read',
                ContentType: contentType,
                old_location: null
            };

            bucket.upload(commonParams, function (err, data) {
                if (err) {
                    reject(err);
                }
                let data_location = data['Location'];
                params.old_location = data['Location'];
                if (folder === 'monument' && ['jpeg', 'jpg', 'png'].includes(ext)) {
                    bucket.upload(params, function (err, data) {
                        if (err) {
                            reject(err);
                        }
                        resolve(params.old_location);
                    })
                } else {
                    resolve(data_location);
                }
            });
        });
    }
}

