import * as $ from "jquery";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { Routes, RouterModule } from "@angular/router";

import { UiSwitchModule } from "ngx-ui-switch";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { FullComponent } from "./layouts/full/full.component";
import { BlankComponent } from "./layouts/blank/blank.component";

import { NavigationComponent } from "./shared/header-navigation/navigation.component";
import { SidebarComponent } from "./shared/sidebar/sidebar.component";
import { BreadcrumbComponent } from "./shared/breadcrumb/breadcrumb.component";
import { SharedModule } from "./shared/shared.module";

import { MonumentViewComponent } from "./_modals/monument-view/monument-view.component";
import { MonumentDeleteComponent } from "./_modals/monument-delete/monument-delete.component";

import { routes } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SpinnerComponent } from "./shared/spinner.component";

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

import { AgmCoreModule } from "@agm/core";
import { AgmJsMarkerClustererModule } from "@agm/js-marker-clusterer";
import { NgxGalleryModule } from "ngx-gallery";

import { AuthService } from "./_services/auth.service";
import { ApiService } from "./_services/api.service";
import { UploadFileService } from "./_services/upload-file.service";
import { StorageService } from "./_services/storage.service";
import { UtilService } from "./_services/util.service";
import { AuthGuard } from "./_guards/auth.guard";
import { GrantGuard } from "./_guards/grant.guard";
import { TokenInterceptor } from "./_interceptors/token.interceptor";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20,
};

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    MonumentViewComponent,
    MonumentDeleteComponent,
  ],
  entryComponents: [MonumentViewComponent, MonumentDeleteComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    SharedModule,
    RouterModule.forRoot(routes),
    PerfectScrollbarModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCLzrHA9sHATXPdIXuVruZh1T479S4eHh0",
      libraries: ["places", "drawing", "geometry"],
    }),
    AgmJsMarkerClustererModule,
    NgxGalleryModule,
    UiSwitchModule
  ],
  providers: [
    AuthService,
    ApiService,
    UploadFileService,
    StorageService,
    UtilService,
    AuthGuard,
    GrantGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
