import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

import { UtilService } from "./util.service";

import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import "rxjs/add/observable/throw";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private apiUrl = environment.api_url;
  url: any;
  data: any;

  constructor(private http: HttpClient, private utilService: UtilService) {}

  public updateMe(data) {
    this.url = this.apiUrl + "auths/me";
    return this.http.put(this.url, data);
  }

  public createState(newState) {
    this.url = this.apiUrl + "states/create";
    return this.http.post(this.url, newState);
  }

  public updateState(updatedState) {
    this.url = this.apiUrl + "states/update";
    return this.http.post(this.url, updatedState);
  }

  public deleteState(_id) {
    this.url = this.apiUrl + "states/delete?_id=" + _id;
    return this.http.get(this.url);
  }

  public getStates(search) {
    this.url = this.apiUrl + "states/get?search=" + search;
    return this.http.get(this.url);
  }

  public createLimit(newLimit) {
    this.url = this.apiUrl + "limits/create";
    return this.http.post(this.url, newLimit);
  }

  public updateLimit(updatedLimit) {
    this.url = this.apiUrl + "limits/update";
    return this.http.post(this.url, updatedLimit);
  }

  public deleteLimit(_id) {
    this.url = this.apiUrl + "limits/delete?_id=" + _id;
    return this.http.get(this.url);
  }

  public getLimits(search, stateId = null) {
    this.url =
      this.apiUrl +
      "limits/get?search=" +
      search +
      (stateId ? `&stateId=${stateId}` : "");
    return this.http.get(this.url);
  }

  public createRoute(newRoute) {
    this.url = this.apiUrl + "routes/create";
    return this.http.post(this.url, newRoute);
  }

  public updateRoute(updatedRoute) {
    this.url = this.apiUrl + "routes/update";
    return this.http.post(this.url, updatedRoute);
  }

  public deleteRoute(_id) {
    this.url = this.apiUrl + "routes/delete?_id=" + _id;
    return this.http.get(this.url);
  }

  public getRoutes(search, limitId = null) {
    this.url =
      this.apiUrl +
      `routes/get?search=${search}` +
      (limitId ? `&limitId=${limitId}` : "");
    return this.http.get(this.url);
  }

  public createBlock(newBlock) {
    this.url = this.apiUrl + "blocks/create";
    return this.http.post(this.url, newBlock);
  }

  public updateBlock(updatedBlock) {
    this.url = this.apiUrl + "blocks/update";
    return this.http.post(this.url, updatedBlock);
  }

  public deleteBlock(_id) {
    this.url = this.apiUrl + "blocks/delete?_id=" + _id;
    return this.http.get(this.url);
  }

  public getBlocks(search, routeId) {
    this.url =
      this.apiUrl + "blocks/get?search=" + search + "&routeId=" + routeId;
    return this.http.get(this.url);
  }

  public getTypesByProviders(search, providerId) {
    this.url =
      this.apiUrl + "types/getByProviders?search=" + search + "&providerId=" + providerId;
    return this.http.get(this.url);
  }

  public getSubtypesByTypes(search, typeId) {
    this.url =
      this.apiUrl + "subtypes/getByTypes?search=" + search + "&typeId=" + typeId;
    return this.http.get(this.url);
  }

  public createMonument(newMonument) {
    this.url = this.apiUrl + "monuments/create";
    return this.http.post(this.url, newMonument);
  }

  public updateMonument(updatedMonument) {
    this.url = this.apiUrl + "monuments/update";
    return this.http.post(this.url, updatedMonument);
  }

  public deleteMonument(_id) {
    this.url = this.apiUrl + "monuments/delete?_id=" + _id;
    return this.http.get(this.url);
  }

  public getMonuments(filterBase) {
    this.url = this.apiUrl + "monuments/get";
    return this.http.post(this.url, filterBase);
  }

  public getAnalysis(filterBase) {
    this.url = this.apiUrl + "monuments/getAnalysis";
    return this.http.post(this.url, filterBase);
  }

  public getAssets(filterBase) {
    this.url = this.apiUrl + "monuments/getAssets";
    return this.http.post(this.url, filterBase);
  }

  public getNumOfFix() {
    this.url = this.apiUrl + "monuments/getNumOfFix";
    return this.http.get(this.url);
  }

  public getNumOfSuggest() {
    this.url = this.apiUrl + "monuments/getNumOfSuggest";
    return this.http.get(this.url);
  }

  public getBlocksAround(position) {
    this.url = this.apiUrl + "monuments/getBlocksAround";
    return this.http.post(this.url, position);
  }

  public getSubtypesFiltered(typeId, providerId) {
    this.url =
      this.apiUrl +
      "monuments/getSubtypesFiltered?typeId=" +
      typeId +
      "&providerId=" +
      providerId;
    return this.http.get(this.url);
  }

  public getMonumentById(_id) {
    this.url = this.apiUrl + "monuments/getMonumentById?_id=" + _id;
    return this.http.get(this.url);
  }

  public getProjectById(_id, ref = null) {
    this.url = this.apiUrl + "projects/getProjectById?_id=" + _id;
    if (ref) {
      const joint = [];
      Object.keys(ref).forEach((key) => {
        joint.push(`${key}=${ref[key].join(",")}`);
      });
      this.url += `&${joint.join("&")}`;
    }
    return this.http.get(this.url);
  }

  public createAdmin(newAdmin) {
    this.url = this.apiUrl + "admins/create";
    return this.http.post(this.url, newAdmin);
  }

  public updateAdmin(updatedAdmin) {
    this.url = this.apiUrl + "admins/update";
    return this.http.post(this.url, updatedAdmin);
  }

  public deleteAdmin(_id) {
    this.url = this.apiUrl + "admins/delete?_id=" + _id;
    return this.http.get(this.url);
  }

  public getAdmins(search) {
    this.url = this.apiUrl + "admins/get?search=" + search;
    return this.http.get(this.url);
  }

  public createOperator(newOperator) {
    this.url = this.apiUrl + "operators/create";
    return this.http.post(this.url, newOperator);
  }

  public updateOperator(updatedOperator) {
    this.url = this.apiUrl + "operators/update";
    return this.http.post(this.url, updatedOperator);
  }

  public deleteOperator(_id) {
    this.url = this.apiUrl + "operators/delete?_id=" + _id;
    return this.http.get(this.url);
  }

  public getOperators(search) {
    this.url = this.apiUrl + "operators/get?search=" + search;
    return this.http.get(this.url);
  }

  public createVerifier(newVerifier) {
    this.url = this.apiUrl + "verifiers/create";
    return this.http.post(this.url, newVerifier);
  }

  public updateVerifier(updatedVerifier) {
    this.url = this.apiUrl + "verifiers/update";
    return this.http.post(this.url, updatedVerifier);
  }

  public deleteVerifier(_id) {
    this.url = this.apiUrl + "verifiers/delete?_id=" + _id;
    return this.http.get(this.url);
  }

  public getVerifiers(search) {
    this.url = this.apiUrl + "verifiers/get?search=" + search;
    return this.http.get(this.url);
  }

  public createCategory(newCategory) {
    this.url = this.apiUrl + "categorys/create";
    return this.http.post(this.url, newCategory);
  }

  public updateCategory(updatedCategory) {
    this.url = this.apiUrl + "categorys/update";
    return this.http.post(this.url, updatedCategory);
  }

  public deleteCategory(_id) {
    this.url = this.apiUrl + "categorys/delete?_id=" + _id;
    return this.http.get(this.url);
  }

  public getCategorys(search) {
    this.url = this.apiUrl + "categorys/get?search=" + search;
    return this.http.get(this.url);
  }

  public getSubcatsByCat(categoryId) {
    this.url = this.apiUrl + "new/global/subcats?categoryId=" + categoryId;
    return this.http.get(this.url);
  }

  public getSubcatsByCats(categoryIdArr) {
    this.url = this.apiUrl + "new/global/subcats?" + categoryIdArr;
    return this.http.get(this.url);
  }

  public createUpdateSubcats(subcats) {
    this.url = this.apiUrl + "new/global/subcats/update";
    return this.http.post(this.url, subcats);
  }

  public updateSubcat(subcats) {
    this.url = this.apiUrl + "new/global/subcats/";
    return this.http.patch(this.url, subcats);
  }

  public updateTag(body) {
    this.url = this.apiUrl + "categorys/tagUpdate";
    return this.http.patch(this.url, body);
  }

  public createSubcats(subcats) {
    this.url = this.apiUrl + "new/global/subcats/";
    return this.http.post(this.url, subcats);
  }

  public deleteSubcats(_id) {
    this.url = this.apiUrl + "new/global/subcats?_id=" + _id;
    return this.http.delete(this.url);
  }

  public createType(newType) {
    this.url = this.apiUrl + "types/create";
    return this.http.post(this.url, newType);
  }

  public updateType(updatedType) {
    this.url = this.apiUrl + "types/update";
    return this.http.post(this.url, updatedType);
  }

  public deleteType(_id) {
    this.url = this.apiUrl + "types/delete?_id=" + _id;
    return this.http.get(this.url);
  }

  public getTypes(search) {
    this.url = this.apiUrl + "types/get?search=" + search;
    return this.http.get(this.url);
  }

  public createBrand(newBrand) {
    this.url = this.apiUrl + "brands/create";
    return this.http.post(this.url, newBrand);
  }

  public updateBrand(updatedBrand) {
    this.url = this.apiUrl + "brands/update";
    return this.http.post(this.url, updatedBrand);
  }

  public deleteBrand(_id) {
    this.url = this.apiUrl + "brands/delete?_id=" + _id;
    return this.http.get(this.url);
  }

  public getBrand(_id) {
    this.url = this.apiUrl + "brands/getBrandById?_id=" + _id;
    return this.http.get(this.url);
  }

  public getBrands(search) {
    this.url = this.apiUrl + "brands/get?search=" + search;
    return this.http.get(this.url);
  }

  public getBrandsBySubCats(search, subcats, categories) {
    this.url = this.apiUrl + "brands/getFilter";
    return this.http.post(this.url, { search, subcats, categories });
  }

  public createProvider(newProvider) {
    this.url = this.apiUrl + "providers/create";
    return this.http.post(this.url, newProvider);
  }

  public updateProvider(updatedProvider) {
    this.url = this.apiUrl + "providers/update";
    return this.http.post(this.url, updatedProvider);
  }

  public deleteProvider(_id) {
    this.url = this.apiUrl + "providers/delete?_id=" + _id;
    return this.http.get(this.url);
  }

  public getProviders(search) {
    this.url = this.apiUrl + "providers/get?search=" + search;
    return this.http.get(this.url);
  }

  public updateSubtypes(providerId, subtypes) {
    this.url = this.apiUrl + "subtypes/update";
    this.data = {
      providerId: providerId,
      subtypes: subtypes,
    };
    return this.http.post(this.url, this.data);
  }

  public getSubtypes(providerId) {
    this.url = this.apiUrl + "subtypes/get?providerId=" + providerId;
    return this.http.get(this.url);
  }

  public createCycle(newCycle, search, pageNum) {
    this.url = this.apiUrl + "cycles/create";
    this.data = {
      newCycle: newCycle,
      search: search,
      pageNum: pageNum,
    };
    return this.http.post(this.url, this.data);
  }

  public updateCycle(updatedCycle, search, pageNum) {
    this.url = this.apiUrl + "cycles/update";
    this.data = {
      updatedCycle: updatedCycle,
      search: search,
      pageNum: pageNum,
    };
    return this.http.post(this.url, this.data);
  }

  public deleteCycle(_id, search, pageNum) {
    this.url =
      this.apiUrl +
      "cycles/delete?_id=" +
      _id +
      "&search=" +
      search +
      "&pageNum=" +
      pageNum;
    return this.http.get(this.url);
  }

  public delteProject(_id) {
    this.url =
      this.apiUrl +
      "project/delete?_id=" +
      _id
    return this.http.get(this.url);
  }

  public getCycles(search, pageNum = 0) {
    this.url = pageNum
      ? this.apiUrl + "cycles/get?search=" + search + "&pageNum=" + pageNum
      : this.apiUrl + "cycles/getAll?search=" + search;
    return this.http.get(this.url);
  }

  /* ❄❄❄❄❄ */
  public getDashboardData(isAdmin, cycleId) {
    this.url =
      this.apiUrl +
      "extras/getDashboardData?isAdmin=" +
      isAdmin +
      "&cycleId=" +
      cycleId +
      "&today=" +
      this.utilService.getTodayUTC();
    return this.http.get(this.url);
  }

  /* ❄❄❄❄❄ */
  public getDependencyData(dependency) {
    this.url = this.apiUrl + "extras/getDependencyData";
    return this.http.post(this.url, dependency);
  }

  /* ❄❄❄❄❄ */
  public getDependencyCSV(dependency) {
    this.url = this.apiUrl + "extras/getDependencyCSV";
    if (Object.keys(dependency).length) {
      this.url += "?";
      this.url += Object.keys(dependency)
        .map((k) => `${k}=${dependency[k]}`)
        .join("&");
    }
    return this.url;
  }

  /* ❄❄❄❄❄ */
  public getVerifierData(cycleId, verifierId) {
    this.url =
      this.apiUrl +
      "extras/getVerifierData?cycleId=" +
      cycleId +
      "&verifierId=" +
      verifierId;
    return this.http.get(this.url);
  }

  /* ❄❄❄❄❄ */
  public getCycleData(cycleId) {
    this.url =
      this.apiUrl +
      "extras/getCycleData?cycleId=" +
      cycleId +
      "&today=" +
      this.utilService.getTodayUTC();
    return this.http.get(this.url);
  }

  /* ❄❄❄❄❄ */
  public getGlobalData() {
    this.url = this.apiUrl + "extras/getGlobalData";
    return this.http.get(this.url);
  }

  /* ❄❄❄❄❄ */
  public getMonumentsAll({ page, limit }) {
    this.url = this.apiUrl + "extras/getMonumentsAll" + `?page=${page}&limit=${limit}`;
    return this.http.get(this.url);
  }

  /* ❄❄❄❄❄ */
  public getFieldData() {
    this.url = this.apiUrl + "extras/getFieldData";
    return this.http.get(this.url);
  }

  /* ❄❄❄❄❄ */
  public getVerifiersAll() {
    this.url = this.apiUrl + "extras/getVerifiersAll";
    return this.http.get(this.url);
  }

  /* ✪✪✪✪✪ */
  public getTodayCycles() {
    this.url =
      this.apiUrl +
      "projects/getTodayCycles?today=" +
      this.utilService.getTodayUTC();
    return this.http.get(this.url);
  }

  /* ✪✪✪✪✪ */
  public recoverMonuments(offset = 0) {
    this.url = this.apiUrl + "extras/recoverProject?offset=" + offset;
    return this.http.get(this.url);
  }

  /* ✪✪✪✪✪ */
  public getAssignData(cycleId) {
    this.url =
      this.apiUrl +
      "projects/getAssignData?cycleId=" +
      cycleId +
      "&today=" +
      this.utilService.getTodayUTC();
    return this.http.get(this.url);
  }

  /* ✪✪✪✪✪ */
  public getBlockMonuments(cycleId, blockId) {
    this.url =
      this.apiUrl +
      "projects/getBlockMonuments?cycleId=" +
      cycleId +
      "&blockId=" +
      blockId;
    return this.http.get(this.url);
  }

  /* ✪✪✪✪✪ */
  public assignBlockToVerifier(cycleId, verifierId, monuments) {
    this.url = this.apiUrl + "projects/assignBlockToVerifier";
    this.data = {
      cycleId: cycleId,
      verifierId: verifierId,
      monuments: monuments,
    };
    return this.http.post(this.url, this.data);
  }

  /* ✪✪✪✪✪ */
  public getControlData(cycleId) {
    this.url =
      this.apiUrl +
      "projects/getControlData?cycleId=" +
      cycleId +
      "&today=" +
      this.utilService.getTodayUTC();
    return this.http.get(this.url);
  }

  public getVerifierById(verifierId) {
    this.url = this.apiUrl + "verifiers/getVerifierById?id=" + verifierId;
    return this.http.get(this.url, verifierId);
  }
    public getProjectsByCycle(data: any) {
    this.url = this.apiUrl + "extras/getProjectsByCycle?&cycleId=" + data.cycle ;
    return this.http.get(this.url, data.cycle);
  }

  public projectDelete(_id: any) {
    this.url = this.apiUrl + "projects/delete?_id=" + _id ;
    return this.http.get(this.url, _id);
  }

  /* ✪✪✪✪✪ */
  public getMonumentsByIdArr(monumentIdArr) {
    this.url = this.apiUrl + "projects/getMonumentsByIdArr";
    return this.http.post(this.url, monumentIdArr);
  }

  /* ❄❄❄❄❄ */
  public exportResources(key) {
    this.url = this.apiUrl + `extras/${key}`;
    return this.url;
  }

  public getGeneralReport(filterBase) {
    this.url = this.apiUrl + "projects/getGeneralReport";
    return this.http.post(this.url, filterBase);
  }

  public getBrandsReport(filterBase) {
    this.url = this.apiUrl + "projects/getBrandsReport";
    return this.http.post(this.url, filterBase);
  }

  public getCompetitionReport(filterBase) {
    this.url = this.apiUrl + "projects/getCompetitionReport";
    return this.http.post(this.url, filterBase);
  }

  public getCycleReport(filterBase) {
    this.url = this.apiUrl + "projects/getCycleReport";
    return this.http.post(this.url, filterBase);
  }

  public getProviderReport(filterBase) {
    this.url = this.apiUrl + "projects/getProviderReport";
    return this.http.post(this.url, filterBase);
  }

  public getCategoryReport(filterBase) {
    this.url = this.apiUrl + "projects/getCategoryReport";
    return this.http.post(this.url, filterBase);
  }

  public getSubcatReport(filterBase) {
    this.url = this.apiUrl + "projects/getSubcatReport";
    return this.http.post(this.url, filterBase);
  }

  public getBrandReport(filterBase) {
    this.url = this.apiUrl + "projects/getBrandReport";
    return this.http.post(this.url, filterBase);
  }

  public getGeneralProjects(filterBase) {
    this.url = this.apiUrl + "projects/getGeneralProjects";
    return this.http.post(this.url, filterBase);
  }

  public getBrandProjects(filterBase) {
    this.url = this.apiUrl + "projects/getBrandProjects";
    return this.http.post(this.url, filterBase);
  }

  public getCycleProjects(filterBase) {
    this.url = this.apiUrl + "projects/getCycleProjects";
    return this.http.post(this.url, filterBase);
  }

  public updateProject(data) {
    this.url = this.apiUrl + "projects/update";
    return this.http.post(this.url, data);
  }
}
