import { Component, OnInit } from "@angular/core";

declare var $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  title = "app";

  ngOnInit() {
    $(document).on("click", ".matteus-item", function(event) {
      $(event.target)
        .addClass("active")
        .parent()
        .siblings()
        .children()
        .removeClass("active");
    });
  }
}
