import { CanActivate, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { StorageService } from "../_services/storage.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private storageService: StorageService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.storageService.get("user")) {
      return true;
    }

    const type = this.storageService.get("type");
    this.router.navigateByUrl(type === "admin" ? "/admin-login" : "/login");
    return false;
  }
}
