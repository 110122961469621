import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private prefix = 'adsmart_';

  constructor() { }

  public set(key, value) {
    key = this.prefix + key;
    localStorage.setItem(key, JSON.stringify(value));
    return;
  }

  public get(key) {
    key = this.prefix + key;
    return JSON.parse(localStorage.getItem(key));
  }

  public remove(key) {
    key = this.prefix + key;
    localStorage.removeItem(key);
    return;
  }

  public clear() {
    localStorage.clear();
  }
}
