import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { StorageService } from "./storage.service";
import { environment } from "../../environments/environment";

import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import "rxjs/add/observable/throw";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private apiUrl = environment.api_url;
  url: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService
  ) {}

  public signup(signupUser) {
    this.url = this.apiUrl + "auths/signup";
    return this.http.post(this.url, signupUser);
  }

  public login(loginUser) {
    this.url = this.apiUrl + "auths/login";
    return this.http.post(this.url, loginUser);
  }

  public recover(recoverUser) {
    this.url = this.apiUrl + "auths/recover";
    return this.http.post(this.url, recoverUser);
  }

  public logout() {
    const type = this.storageService.get("type");
    this.storageService.clear();
    this.router.navigateByUrl(type === "admin" ? "/admin-login" : "/login");
  }
}
