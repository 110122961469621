import { CanActivate, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { StorageService } from "../_services/storage.service";

@Injectable({
  providedIn: "root",
})
export class GrantGuard implements CanActivate {
  constructor(private router: Router, private storageService: StorageService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var user = this.storageService.get("user"),
      grant = user.info.grant,
      url = route.data.url;

    const path = this.storageService.get("type");
    if (url && url.startsWith("admin")) {
      // Admin Dashboard
      if (path !== "admin") {
        this.router.navigateByUrl("/admin-login");
        return false;
      } else {
        if (["admin.general"].includes(url)) {
          return true;
        }
        this.router.navigateByUrl("/admin/general");
        return false;
      }
    } else {
      if (path === "admin" && url) {
        this.router.navigateByUrl("/admin/general");
        return false;
      }
      if (url === "dashboard" || !url) return true;
      if (user.auth.role == "super") {
        if (url == "hierarchy.dependency") return true;
        if (url == "hierarchy.monument") return true;
        if (url == "hierarchy.monument-create") return true;
        if (url == "hierarchy.monument-update") return true;
        if (url == "hierarchy.monument-approve") return true;
        if (url == "hierarchy.poi") return true;

        if (url == "user.admin") return true;
        if (url == "user.operator") return true;
        if (url == "user.verifier") return true;

        if (url == "ads.category") return true;
        if (url == "ads.type") return true;
        if (url == "ads.brand") return true;
        if (url == "ads.provider") return true;
        if (url == "ads.cycle") return true;

        if (url == "global") return true;
        if (url == "field") return true;
        if (url == "analytics") return true;
      } else if (user.auth.role == "admin") {
        if (url == "hierarchy.dependency" && grant["hierarchy"]["dependency"])
          return true;
        if (url == "hierarchy.monument" && grant["hierarchy"]["dependency"])
          return true;
        if (
          url == "hierarchy.monument-create" &&
          grant["hierarchy"]["dependency"]
        )
          return true;
        if (
          url == "hierarchy.monument-update" &&
          grant["hierarchy"]["dependency"]
        )
          return true;
        if (
          url == "hierarchy.monument-approve" &&
          grant["hierarchy"]["dependency"]
        )
          return true;
        if (url == "hierarchy.poi" && grant["hierarchy"]["poi"]) return true;

        if (url == "user.operator" && grant["user"]["operator"]) return true;
        if (url == "user.verifier" && grant["user"]["verifier"]) return true;

        if (url == "ads.category" && grant["ads"]["category"]) return true;
        if (url == "ads.type" && grant["ads"]["type"]) return true;
        if (url == "ads.brand" && grant["ads"]["brand"]) return true;
        if (url == "ads.provider" && grant["ads"]["provider"]) return true;
        if (url == "ads.cycle" && grant["ads"]["cycle"]) return true;

        if (url == "global" && grant["global"]) return true;
        if (url == "field" && grant["field"]) return true;
        if (url == "analytics" && grant["analytics"]) return true;
      } else if (user.auth.role == "operator") {
        if (url == "hierarchy.dependency") return true;
        if (url == "hierarchy.monument") return true;
        if (url == "hierarchy.poi") return true;

        if (url == "verification.assignment") return true;
        if (url == "verification.control") return true;
      }

      this.router.navigateByUrl("/dashboard");
      return false;
    }
  }
}
